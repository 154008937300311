@import "../src/styles/global";

body,
button { 
  margin: 0;
  font-family: "Roboto Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lighter-fundation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
