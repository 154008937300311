@import "../styles/global";

body {
  cursor: none;
}
.cursor {
  display: block;
  pointer-events: none;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 1000;
  }
}

circle {
  fill: #814f47;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .cursor {
    display: none;
  }

  body {
    cursor: pointer;
  }
}
