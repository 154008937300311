$dark-shade: #4056a1;
$dark-colorful: #f13c20;
$ligther-shade: #c5cbe3;
$ligther-colorful: #d79922;
$light-fundation: #efe2ba;
$lighter-fundation: #f2f0ea;
$white-fundation: #ffffff;
$dark-letters: #504845;

@mixin container {
  display: flex;
  height: 100vh;
}

@mixin centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $lighter-fundation;
  font-family: "Cabin Sketch", cursive;
  font-size: 50px;
}
