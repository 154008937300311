@import "../styles/global";

.articles-container {
  @include container;
  background-color: $ligther-colorful;
  height: 25vh;

  &__title {
    @include section-title;
    font-size: 30px;
    width: 50%;
  }

  &__content {
    @include centered-content;
    width: 50%;
    color: ivory;
    align-items: flex-start;

    &__article {
      display: flex;

      h3 {
        font-weight: initial;
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
      }

      i {
        font-size: 28px;
        color: $dark-colorful;
      }
    }
  }
}

@media (min-width: 650px) {
  .articles-container {
    &__title {
      font-size: 50px;
    }
  }
}
