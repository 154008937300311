@import "../styles/global";

.contact-container {
  display: flex;
  height: 32vh;
  background-color: $dark-shade;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  color: $lighter-fundation;

  h2 {
    font-family: "Cabin Sketch", cursive;
    font-size: 40px;
  }

  &__links {
    width: 25%;
    display: flex;
    justify-content: space-evenly;

    a {
      padding-left: 5px;
    }

    i {
      font-size: 40px;
    }
  }
 
}
