@import "../styles/global";

.about-container {
  @include container;
  height: 70vh;
  margin-top: 50px;

  &__title {
    @include section-title;
    width: 20%;
    background-color: $dark-shade;
  }

  &__content {
    @include centered-content;
    width: 100%;
    background-color: $dark-shade;

    img {
      height: 8em;
      width: 8em;
      border-radius: 50%;
    }

    h3 {
      color: ivory;
    }

    h4 {
      font-weight: initial;
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
      color: ivory;
    }

    span {
      font-weight: bold;
    }
  }
}

@media (min-width: 650px) {
  .about-container {
    height: 50vh;
  }
}
