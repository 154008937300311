@import "../styles/global";

.project-item {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  a{
    color: $dark-colorful;
    font-size: 1.5em;
    text-decoration: none;
    font-weight: bold;
  }

  img {
    max-width: 80%;
  }

  &__tech-stack{
    width: 50px;
    margin: 10px;
  }
  
}
