@import "../styles/global";

.header-container {
  @include container;
  background-color: $lighter-fundation;
  flex-direction: column;
  color: $dark-letters;

  &__menu {
    font-weight: bold;
    display: flex;
    justify-content: center;
    z-index: 5;
    height: 70px;
    width: 100%;
  }

  &__menu div {
    padding: 20px;
    cursor: pointer;
  }

  &__title {
    @include centered-content;
    height: 100%;

    &__name {
      font-size: 60px;
      font-family: "Cabin Sketch", cursive;
      font-weight: bold;
      color: $dark-colorful;
    }

    &__lastname {
      color: $ligther-colorful;
    }

    &__contact {
      width: 30%;
      display: flex;
      justify-content: space-evenly;

      i {
        font-size: 25px;
        color: $dark-shade;
      }
    }
  }

  &__scroll-down {
    justify-content: center;
    display: flex;

    button {
      border: none;
      background-color: inherit;
      padding: 20px;
      color: $dark-colorful;
      cursor: pointer;

      i {
        font-size: 20px;
        animation: movement 2s ease infinite;
        color: $dark-shade;
      }
    }
  }
}

@keyframes movement {
  from {
    transform: translateY(-25%);
  }
  to {
    transform: translateY(25%);
  }
}

@media (min-width: 650px) {
  .header-container {
    &__title {
      &__name {
        font-size: 100px;
      }

      &__contact {
        width: 10%;
      }
    }
  }
}
