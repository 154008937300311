@import "../styles/global";

.projects-container {
  flex-direction: column;

  &__title {
    @include section-title;
    background-color: $dark-colorful;
    font-size: 50px;
    height: 15vh;
  }

  &__content {
    background-color: $lighter-fundation;
    @include centered-content;
    margin-left: auto;
    margin-right: 0;
    min-height: 100vh;
    height: auto;
    padding: 20px 0px;

    hr {
      width: 70%;
      border: 1px solid $dark-colorful !important;
      margin: 30px;
    }
  }
 
}

@media (min-width: 650px) {
  .projects-container {
    display: flex;
    flex-direction: row;

    &__title { 
      height: 100vh;
      width: 20%;
      font-size: 25px;
    }

    &__content {  
      width: 80%;
      padding: 60px 0px; 
    }

    .fixed {
      position: fixed;
      top: 0;
    }

    .endOfContent {
      align-self: flex-end;
    }
  }
}

@media (min-width: 1400px) {
  .projects-container {
    &__title {
      font-size: 50px;
    }
  }
}
